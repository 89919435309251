import { PageProps } from 'gatsby';
import { BannerData } from 'packages/innedit';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import params from '~/params/banner.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageBannersUpdate: FC<PageProps & EspaceProps & UserProps> = props => {
  const {
    espace,
    user,
    params: { channelId, bannerId, espaceId },
  } = props;

  return (
    <TemplateEspace
      collectionName="banners"
      docId={bannerId}
      espace={espace}
      user={user}
    >
      <CMSView>
        <Form
          {...props}
          docId={bannerId}
          itemPathnamePrefix={`/espaces/${espaceId}/channels/${channelId}/banners/`}
          model={new BannerData({ espaceId, params })}
          name="banner"
          type="update"
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageBannersUpdate);
